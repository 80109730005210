import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';

import ImgsViewer from 'react-images-viewer'

import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
  } from 'video-react';


let images = [
    "https://s3.amazonaws.com/bmprod.me/pictures/Aaron+Munhoz+working+in+his+taco+stand.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/BJ+Young+moments+after+getting+submitted.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Ben+Bergquam+a+pro+Kavanugh+supporter+speaking+to+his+livestream+at+an+anti+Kavanugh+rally..JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Cesar+Balmaceda.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Classic+Car+Show.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Colorful+sunrise+in+Belmar,+NJ.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Crystal+Caves+Bermuda+.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/D.C.+Protester+getting+arrested_6.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/DONT+NAME+IT.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Da_Mon+Blackshear.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Dont+name.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Doug+Wells.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Down+with+line+cutters.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Emotionless+police_IN.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Flags+in+Philly.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Josh+Altum+_+BJ+Young+staredown.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Keshtov_s+uppercuts+Bernardo.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Lashawn+Alcocks+standing+over+Chase+Hooper.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Man+walking+into+Supreme+Court+building+.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Mark+Scarbado+serving+up+water+ice..JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Nick+Sergi+watching++the+sunrise+in+Belmar,+NJ.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Paul+Capaldo+head+kick+KO+vs+Bobby+Malcolm.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Philadelphia+City+Hall.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Police+Chief+gives+protestors+a+first+warning_1.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Police+Chief+signals+to+begin+arresting_4.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Police+Chief_3.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Police+called+into+to+start+arrests_5.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Portrait+of+young+anti-abortion+protestor_1.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Raining+day+in+Philadelphia.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Reporter+with+an+anti-abortion+protestor_IN_2.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Small+alleyway+in+Philadelphia.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/The+shoes+of+the+linked+armed+protestors_2.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Welcome+Patriot_IN.jpg",
    "https://s3.amazonaws.com/bmprod.me/pictures/Young+homeless+girl+trying+to+stay+warm+.JPG",
    "https://s3.amazonaws.com/bmprod.me/pictures/Young+protestors__IN.jpg"
]

let names = [
    "Aaron Munhoz working in his taco stand",
    "BJ Young moments after getting submitted",
    "Ben Bergquam a pro Kavanugh supporter speaking to his livestream at an anti Kavanugh rally.",
    "Cesar Balmaceda",
    "Classic Car Show",
    "Colorful sunrise in Belmar, NJ",
    "Crystal Caves Bermuda ",
    "D.C. Protester getting arrested",
    "",
    "Da_Mon Blackshear",
    "",
    "Doug Wells",
    "Down with line cutters",
    "Emotionless police\nInauguration 2016",
    "Flags in Philly",
    "Josh Altum _ BJ Young staredown",
    "Keshtov_s uppercuts Bernardo",
    "Lashawn Alcocks standing over Chase Hooper",
    "Man walking into Supreme Court building ",
    "Mark Scarbado serving up water ice.",
    "Nick Sergi watching  the sunrise in Belmar, NJ",
    "Paul Capaldo head kick KO vs Bobby Malcolm",
    "Philadelphia City Hall",
    "Police Chief gives protestors a first warning",
    "Police Chief signals to begin arresting",
    "Police Chief",
    "Police called into to start arrests",
    "Portrait of young anti-abortion protestor",
    "Raining day in Philadelphia",
    "Reporter with an anti-abortion protestor\nInauguration 2016",
    "Small alleyway in Philadelphia",
    "The shoes of the linked armed protestors",
    "Welcome Patriot\nInauguration 2016",
    "Young homeless girl trying to stay warm ",
    "Young protestors\nInauguration 2016",    
]



class ItemGrid extends Component {

    state = {
        activeItem: '*',
        fs: false,
        currImg: 0
    }

    showimg = (imgnum) => {
        this.setState({fs: true, currImg: imgnum})
    }

    closeimg = () => {
        this.setState({fs: false})
    }

    componentDidMount() {
        var imgLoad = new ImagesLoaded('.grid');
    
        imgLoad.on('progress', function(instance, image) {
            this.iso = new Isotope('.grid', {
                itemSelector: '.grid-item',
                layoutMode: "masonry"
            });
        }); 
        
    }
    onFilterChange = (newFilter) => {
        
        this.setState({activeItem: newFilter});
        if (this.iso === undefined) {
            this.iso = new Isotope('.grid', {
            itemSelector: '.grid-item',
            layoutMode: "masonry"
            });
        }
    
    // this.iso.arrange({ filter: newFilter });
        
      if(newFilter === '*') {
        this.iso.arrange({ filter: `*` });
      } else {
        this.iso.arrange({ filter: `.${newFilter}` });
      }
    }

    gotoPrevImg = () => {
        let nimg = this.state.currImg - 1;
        if (nimg < 0) nimg = images.length - 1;
        this.setState({currImg: nimg});
    }

    gotoNextImg = () => {
        let nimg = this.state.currImg + 1;
        if (nimg > images.length - 1) nimg = 0;
        this.setState({currImg: nimg});
    }

    onActive = v => v === this.state.activeItem ? 'active' : '';
    render() {
        let cb = <ControlBar>
            <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={30} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            <VolumeMenuButton disabled />
        </ControlBar>
        let imgs = [];
        for (let i = 0; i < images.length; i++) {
            imgs.push({
                src: images[i],
                caption: names[i]
            })
        }
        let imgviewer = 
        <ImgsViewer
            imgs={imgs}
            isOpen={this.state.fs}
            currImg={this.state.currImg}
            onClickPrev={this.gotoPrevImg}
            onClickNext={this.gotoNextImg}
            onClose={this.closeimg}
        /> 

        let imgtiles = imgs.map(image => {
            // let n = image.caption.split('\n').map((item, i) => {
            //     return <p key={i}>{item}</p>;
            // });
            const k = imgs.indexOf(image);
            return <div className="col-md-6 col-sm-6 col-xs-12 grid-item photography" key={k}>
                    <div className="portfolio hover-style">
                    <img src={image.src} alt=""/>
                        <div className="item-img-overlay">
                            <div className="overlay-info text-center">
                                <h6 className="sm-titl">{image.caption}</h6>
                                <div className="icons">
                                    <button onClick={() => this.showimg(k)} style={{background:"transparent"}}><i className="icon-magnifying-glass"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>						
                </div>
        })

        return(
            <div>
            
            {imgviewer}

            <ul className="list_style portfolio_menu text-center">
                <li className={`${this.onActive('*')}`} data-wow-delay="0.1s" data-filter="*" onClick={() => {this.onFilterChange("*")}}>ALL</li>
                <li className={`${this.onActive('producer')}`} data-wow-delay="0.1s" data-filter="producer" onClick={() => {this.onFilterChange("producer")}}>producer</li>
                <li className={`${this.onActive('videographer')}`} data-wow-delay="0.3s" data-filter="videographer" onClick={() => {this.onFilterChange("videographer")}}>videographer</li>
                <li className={`${this.onActive(`editor`)}`} data-wow-delay="0.6s" data-filter="editor" onClick={()=> {this.onFilterChange("editor")}}>editor</li>
                <li className={`${this.onActive(`videographereditor`)}`} data-wow-delay="0.8s" data-filter="videographereditor" onClick={()=> {this.onFilterChange("videographereditor")}}>videographer & editor</li>
                <li className={`${this.onActive(`photography`)}`} data-wow-delay="0.8s" data-filter="photography" onClick={()=> {this.onFilterChange("photography")}}>photography</li>
            </ul>	

            <div className="grid row">
                    
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/NG52.mp4" >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>

                <div className="col-md-6 col-sm-6 col-xs-12 grid-item producer">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/ITC.mp4" >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item producer">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/3602.mp4">
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>




                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographer">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Camden.mp4">
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographer">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/PP2.mp4"          >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographer">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/farm.mp4"  >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographer">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/3602.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>






                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographereditor">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Wentz.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographereditor">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Bscott_TOPW.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographereditor">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Jenkins_191127_TOPW.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item videographereditor">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Agholor_190917.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>





                <div className="col-md-6 col-sm-6 col-xs-12 grid-item editor">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Duke.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 grid-item editor">
                    <div className="portfolio hover-style">
                    <Player src="https://s3.amazonaws.com/bmprod.me/videos/Huddlefor1002.mp4"   >
                            {cb}
                            <BigPlayButton position="center" />
                    </Player>				
                    </div>
                </div>

                {imgtiles}

            </div>
            </div>
        )
    }
  }

  export default ItemGrid;
