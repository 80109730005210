const jhonData = {
    "name": "Ben Morgan.",
    "name2": "Richard Tea.",
    "role": "Videographer, Editor, & Short Form Online Video Creator,",
    "ptext": "Creative Designer & Developer located in London.",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "http://www.linkedin.com/",
          "className": "social_facebook"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/",
          "className":"social_twitter"
        },
        {
          "name":"googleplus",
          "url":"http://www.googleplus.com",
          "className":"social_googleplus"
        },
        {
            "name":"pinterest",
            "url":"http://www.pinterest.com",
            "className":"social_pinterest"
          }
      ],
      "aboutme": "About Me",
      "aboutdetails": `
      Hey! I’m Ben Morgan. 
      I’ve been creating videos since the age of 14 years, I started by creating small montages of my friends performing flips off the boardwalk in Belmar, NJ. My love of video creating later grew and merged with another one of my passions, shortly after I started creating video game content. Some videos were quick highlights and some I had to gather footage for months just to create one video.
      When it came time for me to go to college, I knew I had to follow my passion for video production and creating a story. While in college I experimented with many forms like online cooking videos, photojournalistic slide shows, short documentary, and many others. 
      While working for the Philadelphia Eagles, I developed a deep passion for creating short community pieces. I loved going to a local high school or to a community event hosted by Eagles, shooting b-roll, standups, and interviews. I would then come back and edit the pieces. Alongside my day to day work I also produce a several hour long sports broadcast for an up and coming sport.
`,
      "service":[
          {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Videographer",
            "sDetails" : "Experienced at shooting sit down interviews, on the spot interview, stand ups, shooting sports, documentaries, cooking shows and more."
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Editor",
            "sDetails" : "Experience in Premier, AVID, and Sony Vegas. Experience editing hype videos, community pieces, studio shows, and more. Proficient in audio editing with experience in using equalizers, compressors, and other audio plugins."
          },
          {
            "id" : 3,
            "iconName": "icon-presentation",
            "serviceTitle": "Photography",
            "sDetails" : "Experience shooting sporting events, portraits, landscapes, cityscapes, and more. I have always had a passion for photography and prior to owning a DSLR, would shoot on my smartphone."
          },
          {
            "id" : 4,
            "iconName": "icon-tools",
            "serviceTitle": "Producer/Director",
            "sDetails" : "Experience directing a short form documentary, producing studio shows, producing short format online content and more."
          },
          {
            "id" : 5,
            "iconName": "icon-linegraph",
            "serviceTitle": "Story Teller",
            "sDetails" : "I have always had a deep passion for storytelling whether it be creating a short form documentary 2 to 3 min community pieces or just one still image, if there’s a story I want to tell it."
          },
          {
            "id" : 6,
            "iconName": "icon-gears",
            "serviceTitle": "Detail Oriented",
            "sDetails" : "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text."
          }
      ],
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: 204 8th ave, Belmar NJ 07719',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Phone: 732-272-6000',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: benjaminmorgan1997@gmail.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: https://bmprod.me',
            }
          ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default jhonData;

