import "../../../node_modules/video-react/dist/video-react.css";


import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Parallax} from 'react-parallax/';
import {Fade , Reveal} from 'react-reveal/';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
  } from 'video-react';
const image = "https://s3.amazonaws.com/bmprod.me/pictures/background.jpg";

class Banner extends Component {

    componentDidMount() {
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state, prevState) {
        if (prevState.isFullscreen && !state.isFullscreen) {
            this.setState({
                videoShown: false
            })
            this.player.pause()
        }
      }

    state = {
        videoShown: false
    }


    render(){
        let jhonData = this.props.jhonData;
        const cb = this.state.videoShown ? (<ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton disabled />
            </ControlBar>) : undefined
        return(
            <section id="home">
                <Parallax bgImage={image} strength={500} className="banner_area">
                    <div className="container">
                        <div className="banner_content">
                            <Reveal effect="fadeInUp">
                                <h5>HELLO</h5>
                            </Reveal>
                            <Reveal effect="fadeInUp" duration={1500}>
                                <h2>I am {jhonData.name}</h2>
                            </Reveal>

                            <h4 className="wow fadeInUp anoimated">Videographer, Editor, & Short Form Online Video Creator</h4>
                            <Fade  bottom cascade duration={1000}>
                                <div className='playBut-container'>
                                    <button className='playBut' onClick={() => {
                                            this.setState({videoShown: true})
                                            this.player.toggleFullscreen()
                                            this.player.play()
                                        }}
                                        >
                                        <svg version="1.1"
                                            x="0px" y="0px" width="213.7px" height="213.7px" viewBox="0 0 213.7 213.7" enableBackground="new 0 0 213.7 213.7"
                                            >

                                            <polygon className='playBut-triangle' id="XMLID_18_" fill="none" strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                                                73.5,62.5 148.5,105.8 73.5,149.1 "/>

                                            <circle className='playBut-circle' id="XMLID_17_" fill="none"  strokeWidth="7" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" cx="106.8" cy="106.8" r="103.3"/>
                                        </svg>
                                    </button>
                                </div>
                            </Fade>
                        </div>
                        <div style={{
                                    display: (this.state.videoShown ? 'inline-block' : 'none'),
                                    height: 0
                                }}>
                            <Player
                                ref={(player) => { this.player = player }}
                                src="https://s3.amazonaws.com/bmprod.me/videos/Rough13.mp4"
                            >
                            {cb}
                            </Player>
                        </div>

                    </div>
                </Parallax>
            </section>
        );
    }
}

export default Banner;